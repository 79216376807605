<template>
  <nav aria-label="pagination" v-if="isReady">
    <ul class="pagination noselect">
      <li
        v-if="pageJump"
        class="page-item"
        v-bind:class="{disabled: !hasPrevious}"
        :title="hasPrevious ? $t('titles.first_page') : ''"
      >
        <span
          class="page-link"
          @click.prevent.stop="page = 1"
          aria-label="Previous"
        >
          <span aria-hidden="true" style="font-size: 80%">
            <i class="fa fa-fast-backward"></i>
          </span>
          <span class="sr-only">Previous</span>
        </span>
      </li>
      <li
        class="page-item"
        v-bind:class="{disabled: !hasPrevious}"
        :title="hasPrevious ? $t('titles.previous_page') : ''"
      >
        <span
          class="page-link"
          @click.prevent.stop="previous"
          aria-label="Previous"
        >
          <span aria-hidden="true" style="font-size: 80%">
            <i class="fa fa-backward"></i>
          </span>
          <span class="sr-only">Previous</span>
        </span>
      </li>
      <li
        class="page-item"
        v-for="(pageNumber, ix) in pageInterval"
        v-bind:class="{active: page == pageNumber}"
        v-bind:key="ix"
        @click.prevent.stop="page = pageNumber"
      >
        <span class="page-link">{{ pageNumber }}</span>
      </li>
      <li
        class="page-item"
        v-bind:class="{disabled: !(hasNext || canLoadNext)}"
        :title="
          hasNext
            ? $t('titles.next_page')
            : canLoadNext
            ? $t('titles.load_next_page')
            : ''
        "
      >
        <span class="page-link" @click.prevent.stop="next">
          <span aria-hidden="true" v-if="busy"
            ><i class="fa fa-refresh fa-spin"></i
          ></span>
          <template v-else>
            <span aria-hidden="true" style="font-size: 80%">
              <i class="fa fa-forward"></i>
            </span>
            <span style="padding-left: 6px" v-if="!pageJump">{{
              $tc("titles.next", 1)
            }}</span>
          </template>
        </span>
      </li>
      <li
        v-if="pageJump"
        class="page-item"
        v-bind:class="{disabled: !(hasNext || canLoadNext)}"
        :title="
          hasNext
            ? $t('titles.last_page')
            : canLoadNext
            ? $t('titles.last_page')
            : ''
        "
      >
        <span class="page-link" @click.prevent.stop="page = pages.length">
          <span aria-hidden="true" v-if="busy"
            ><i class="fa fa-refresh fa-spin"></i
          ></span>
          <template v-else>
            <span aria-hidden="true" style="font-size: 80%">
              <i class="fa fa-fast-forward"></i>
            </span>
            <span style="padding-left: 6px" v-if="!pageJump">{{
              $tc("titles.next", 1)
            }}</span>
          </template>
        </span>
      </li>
    </ul>
    <ul class="pagination noselect" v-if="multiColumnOrder">
      <li class="page-item">
        <span class="page-link" @click.prevent.stop="onShowSortForm">
          <i class="fa fa-unsorted"></i>
        </span>
      </li>
    </ul>
    <div class="pull-right">
      <slot name="summary-before"></slot>
      <div class="summary">
        <span
          :title="
            $t('hints.showing_n_of_n_items', {
              items: (rows || []).length,
              total: nItems
            })
          "
        >
          {{ (rows || []).length }} / {{ nItems }}
        </span>
      </div>
      <slot name="summary-after"></slot>
    </div>
  </nav>
</template>

<script>
import {debounceTime} from "@/utils";
export default {
  name: "TablePaginationNav",
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    pageSize: {
      type: Number,
      required: false,
      default: 25
    },
    nItems: {
      type: Number,
      required: false,
      default: 0
    },
    multiColumnOrder: {
      type: Boolean,
      required: false,
      default: false
    },
    pageJump: {
      type: Boolean,
      required: false,
      default: false
    },
    serverPagination: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isReady: false,
      page: undefined,
      perPage: this.pageSize,
      nbuttons: 3,
      pages: [],
      busy: false
    };
  },
  computed: {
    p() {
      return (
        ("p" in this.$route.query &&
          this.$route.query.p &&
          parseInt(this.$route.query.p, 10)) ||
        1
      );
    },
    rows() {
      if (this.items) {
        return this.pageJump ? this.items : this.paginate(this.items);
      }
      return [];
    },
    pageInterval() {
      // if (this.pageJump) return this.pages;
      let page = this.page;
      let nPages = this.pages.length;
      let nButtons = nPages > this.nbuttons ? this.nbuttons : nPages;
      let from = page - 1;
      let to = page + nButtons - 1;
      if (to > nPages) {
        from = nPages - nButtons;
        to = nPages;
      }
      ////console.log(`nPages=${nPages} page=${page} slice(from=${from}, to=${to})`);
      return this.pages.slice(from, to);
    },
    canLoadNext() {
      if (this.pageJump) return false;
      return (this.nItems || 0) > (this.items || []).length
        ? true
        : this.serverPagination;
    },
    hasNext() {
      return this.page < this.pages.length;
    },
    hasPrevious() {
      return this.page > 1;
    }
  },
  watch: {
    p: {
      handler(n, o) {
        if (n && o) {
          this.page = n;
        }
      },
      immediate: true
    },
    items: {
      handler(nData, oData) {
        if (this.isReady) {
          let n = (nData && nData.length) || 0;
          let o = (oData && oData.length) || 0;
          if (this.busy) {
            if (n >= o) {
              this.setPages();
              this.next();
              return;
            } else {
              this.setPages();
              if (this.pages.length < this.page) {
                this.page = this.pages.length ? this.pages.length : 1;
              }
            }
          } else {
            //this.page = 1; // return to the first page
            this.setPages();
            if (n != o) {
              if (this.pages.length < this.page) {
                this.page = this.pages.length ? this.pages.length : 1;
              }
            }
          }
        } else {
          this.setPages();
        }
      },
      deep: true
    },
    rows: {
      handler(n) {
        if (
          this.$route.query &&
          this.$route.query.p &&
          this.$route.query.p != this.page
        ) {
          this.$router.push({
            query: Object.assign({}, this.$route.query, {p: this.page})
          });
        }
        if (n && n.length && !this.isReady) {
          this.setPages();
        }
        this.$emit("input", this.rows);
      },
      immediate: true
    },
    page(n, o) {
      if (n && o && this.pageJump) {
        this.$emit("pageChanged", n);
      }
    }
  },
  methods: {
    setPages() {
      //this.page = 1;
      let pages = [];
      // let nItems = (this.items || []).length;
      let nItems = this.nItems || (this.items || []).length;
      if (nItems) {
        let numberOfPages = Math.ceil(nItems / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          pages.push(index);
        }
        //this.isReady=(this.pages.length>1)||((this.nItems||0)>nItems);
      }
      this.$set(this, "pages", pages);
      this.isReady = pages.length > 0;
      this.busy = false;
    },
    paginate(items) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return items.slice(from, to);
    },
    loadNextPage: debounceTime(500, function() {
      this.$emit("loadNextPage");
    }),
    next() {
      debounceTime(500, function() {
        this.refresh();
      });
      if (this.hasNext) {
        if (this.page < this.pages.length) {
          this.page++;
        }
      } else {
        if (this.canLoadNext && !this.busy && !this.pageJump) {
          this.busy = true;
          this.loadNextPage();
        }
      }
    },
    previous() {
      if (this.page > 1) {
        this.page--;
      }
    },
    onShowSortForm() {
      this.$emit("showSortForm");
    }
  },
  mounted() {
    this.page = this.p;
  }
};
</script>

<style scoped>
nav {
}
nav::after {
  clear: both;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

ul.pagination {
  margin: auto 10px auto 0;
}
ul.pagination > li:hover {
  opacity: 0.8;
}
.skin-dark .pagination > li:hover {
  opacity: 1;
}

.page-link:hover {
  cursor: pointer;
}

.summary {
  display: inline-block;
  margin-right: 2px;
  border: 1px solid #ddd;
  color: #31708f;
  font-weight: 600;
  opacity: 0.8;
  padding: 6px 12px;
  border-radius: 4px;
  vertical-align: middle;
}

.skin-dark .summary {
  background-color: #2c3b41;
  color: #b8c7ce;
  border-color: #222d32;
}

.summary:hover {
  opacity: 1;
  background-color: whitesmoke;
}

.skin-dark .summary:hover {
  background-color: #222d32;
}
</style>
