var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("paginationBefore"),(_vm.showPagination)?_c('TablePaginationNav',{staticClass:"navigation",attrs:{"items":_vm.orderedEquipmentList,"pageSize":_vm.pageSize,"nItems":_vm.nItems},on:{"loadNextPage":_vm.onLoadNextPage},model:{value:(_vm.pageItems),callback:function ($$v) {_vm.pageItems=$$v},expression:"pageItems"}}):_vm._e(),_vm._t("paginationAfter"),_c('div',{staticClass:"table-container"},[_c('table',{ref:"table",staticClass:"table table-condensed table-bordered table-hover dataTable table-striped table-responsive"},[_c('thead',[_c('tr',[(_vm.multiSelection.key)?[_c('th',{staticClass:"text-primary td-check",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('check', null)}}},[_c('i',{class:_vm.multiSelection.all
                    ? 'fa fa-check-square-o'
                    : 'fa fa-square-o'})])]:_vm._e(),_vm._l((_vm.columns),function(field,ix){return [_c('tho',{key:ix,attrs:{"field":field,"name":field.name,"title":field.title,"nowrap":true},on:{"sort":_vm.onSort}})]})],2)]),(_vm.equipmentList)?_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,class:[
            item.device_enabled && item.enabled ? 'text-info' : 'text-muted',
            _vm.device_id == item.device_id ? 'active' : '',
            'clicable-row'
          ],attrs:{"data-testid":"device","equipment":item,"title":_vm.$t('titles.access_dashboard')},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onSelect(item)}}},[_c('td',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(index))]),(
              _vm.showField('alarm_status') &&
                _vm.$can('view', 'HistoricoAlarmeAcesso')
            )?_c('td',[_c('span',{class:item.device_has_active_alarms ? 'text-danger' : ''},[_c('b',{staticClass:"icon-gap"},[_c('router-link',{attrs:{"to":'/dashboard/alarms/' + item.id,"title":_vm.$t('titles.access_alarms_history')}},[(item.device_has_active_alarms)?_c('i',{staticClass:"fa fa-exclamation-triangle text-danger clicable-icon"}):_c('i',{staticClass:"fa fa-bell clicable-icon"})])],1),_vm._v(" "+_vm._s(_vm.$t(item.device_has_active_alarms ? "alarmed" : "normal"))+" ")])]):_vm._e(),(_vm.showField('connection_status'))?_c('td',[_c('span',{class:item.is_connected ? '' : 'text-danger'},[_c('b',{staticClass:"icon-gap"},[(item.is_connected)?_c('i',{staticClass:"fa fa-link"}):_c('i',{staticClass:"fa fa-exclamation-triangle text-danger"})]),_vm._v(" "+_vm._s(_vm.$tc(item.is_connected ? "connected" : "disconnected", 1))+" ")])]):_vm._e(),(_vm.multiSelection.key)?[_c('td',{staticClass:"text-primary td-check",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('check', item.id)}}},[_c('i',{class:_vm.isSelected(item.id)
                    ? 'fa fa-check-square-o'
                    : 'fa fa-square-o'})])]:_vm._e(),(_vm.showField('connector'))?_c('td',{attrs:{"title":_vm.itemTitle(item)}},[_c('span',{class:item.is_connected ? 'connected' : 'disconnected'},[(_vm.$attrs.draggableItems)?_c('span',{staticClass:"jstree-draggable",attrs:{"draggable":"true","data-item-id":item.id,"data-item-name":item.name},on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('dragstart', $event)}}},[_c('IconConnection',{attrs:{"state":item.connector_status,"disabled":!item.enabled}}),_vm._v(" "+_vm._s(item.name || "")+" ")],1):_c('span',[_c('IconConnection',{attrs:{"state":item.connector_status,"disabled":!item.enabled}}),_vm._v(" "+_vm._s(item.name || "")+" ")],1),(_vm.canEditConnector)?_c('b',{staticClass:"icon-gap btn-xs edit-icon",attrs:{"title":`${_vm.$t('hints.access_to_configuration_form')} ${_vm.$tc(
                    'connector'
                  ).toLowerCase()}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editConnector(item)}}},[_c('i',{staticClass:"fa fa-wrench clicable-icon"})]):_vm._e()])]):_vm._e(),(_vm.showField('serial_number'))?_c('td',{attrs:{"data-testid":"item.sn","title":_vm.itemTitle(item)}},[_vm._v(" "+_vm._s(item.serial_number)+" ")]):_vm._e(),(_vm.showField('location'))?_c('td',{attrs:{"title":_vm.itemTitle(item)}},[_c('span',{staticClass:"text-default"},[_vm._v(_vm._s(item.location_name || ""))])]):_vm._e(),(_vm.showField('device'))?_c('td',{staticClass:"device-column",class:['not-clicable', {'text-muted': !item.device_enabled}],attrs:{"title":`#${item.id}-${item.device_name || item.name || ''}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDeviceSelected(item)}}},[_c('span',[_c('IconConnection',{attrs:{"state":_vm.deviceConnectionStatus(item),"disabled":!item.device_enabled}}),(_vm.$can('view', 'HistoricoAlarmeAcesso'))?_c('b',{class:['icon-gap', {disabled: !item.device_enabled}]},[_c('router-link',{attrs:{"to":'/dashboard/alarms/' + item.id,"title":_vm.$t('titles.access_alarms_history')}},[(item.device_has_active_alarms)?_c('i',{staticClass:"fa fa-bell text-danger clicable-icon"}):_c('i',{staticClass:"fa fa-bell clicable-icon"})])],1):_vm._e(),_vm._v(" "+_vm._s(item.device_name || item.name || "")+" "),_c('i',{staticClass:"fa fa-dashboard"}),(_vm.canEditDevice)?_c('b',{staticClass:"icon-gap edit-icon",attrs:{"title":`${_vm.$t('hints.access_to_configuration_form')} ${_vm.$tc(
                    'device'
                  ).toLowerCase()}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editDevice(item)}}},[_c('i',{staticClass:"fa fa-wrench clicable-icon"})]):_vm._e()],1)]):_vm._e(),(_vm.showField('process_area'))?_c('td',{class:{'text-muted': !item.device_enabled},attrs:{"title":`click: ${_vm.$t('titles.access_dashboard')}`}},[_c('span',[_vm._v(_vm._s(item.device_process_area_name))])]):_vm._e(),_vm._l((_vm.equipmentCustomFields),function(customField,index2){return _c('td',{key:index2},[_vm._v(" "+_vm._s(_vm.getCustomFieldValue(item, customField))+" ")])})],2)}),0):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"10"}},[_vm._v(_vm._s(_vm.$t("no_equipment_was_found")))])])])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }