<template>
  <div>
    <slot name="paginationBefore"></slot>
    <TablePaginationNav
      v-if="showPagination"
      v-model="pageItems"
      v-bind:items="orderedEquipmentList"
      v-bind:pageSize="pageSize"
      v-bind:nItems="nItems"
      v-on:loadNextPage="onLoadNextPage"
      class="navigation"
    />
    <slot name="paginationAfter"></slot>
    <div class="table-container">
      <table
        class="table table-condensed table-bordered table-hover dataTable table-striped table-responsive"
        ref="table"
      >
        <thead>
          <tr>
            <template v-if="multiSelection.key">
              <th
                class="text-primary td-check"
                @click.stop.prevent="$emit('check', null)"
              >
                <i
                  :class="
                    multiSelection.all
                      ? 'fa fa-check-square-o'
                      : 'fa fa-square-o'
                  "
                ></i>
              </th>
            </template>
            <template v-for="(field, ix) in columns">
              <tho
                :key="ix"
                :field="field"
                :name="field.name"
                :title="field.title"
                :nowrap="true"
                v-on:sort="onSort"
              />
            </template>
          </tr>
        </thead>
        <tbody v-if="equipmentList">
          <tr
            :class="[
              item.device_enabled && item.enabled ? 'text-info' : 'text-muted',
              device_id == item.device_id ? 'active' : '',
              'clicable-row'
            ]"
            data-testid="device"
            v-for="(item, index) in items"
            v-bind:key="index"
            v-bind:equipment="item"
            v-on:click.prevent.stop="onSelect(item)"
            v-bind:title="$t('titles.access_dashboard')"
          >
            <td style="display: none">{{ index }}</td>
            <!-- alarm_status -->
            <td
              v-if="
                showField('alarm_status') &&
                  $can('view', 'HistoricoAlarmeAcesso')
              "
            >
              <span
                v-bind:class="
                  item.device_has_active_alarms ? 'text-danger' : ''
                "
              >
                <b class="icon-gap">
                  <router-link
                    v-bind:to="'/dashboard/alarms/' + item.id"
                    :title="$t('titles.access_alarms_history')"
                  >
                    <i
                      class="fa fa-exclamation-triangle text-danger clicable-icon"
                      v-if="item.device_has_active_alarms"
                    ></i>
                    <i class="fa fa-bell clicable-icon" v-else></i>
                  </router-link>
                </b>
                {{ $t(item.device_has_active_alarms ? "alarmed" : "normal") }}
              </span>
            </td>
            <!-- connection_status -->
            <td v-if="showField('connection_status')">
              <span v-bind:class="item.is_connected ? '' : 'text-danger'">
                <b class="icon-gap">
                  <i class="fa fa-link" v-if="item.is_connected"></i>
                  <i v-else class="fa fa-exclamation-triangle text-danger"></i>
                </b>
                {{ $tc(item.is_connected ? "connected" : "disconnected", 1) }}
              </span>
            </td>
            <!-- begin multi selection -->
            <template v-if="multiSelection.key">
              <td
                class="text-primary td-check"
                @click.stop.prevent="$emit('check', item.id)"
              >
                <i
                  :class="
                    isSelected(item.id)
                      ? 'fa fa-check-square-o'
                      : 'fa fa-square-o'
                  "
                ></i>
              </td>
            </template>
            <!-- end multi selection -->

            <!-- connector -->
            <td v-if="showField('connector')" :title="itemTitle(item)">
              <span :class="item.is_connected ? 'connected' : 'disconnected'">
                <span
                  v-if="$attrs.draggableItems"
                  class="jstree-draggable"
                  draggable="true"
                  :data-item-id="item.id"
                  :data-item-name="item.name"
                  @mousedown.stop.prevent="$emit('dragstart', $event)"
                >
                  <IconConnection
                    :state="item.connector_status"
                    :disabled="!item.enabled"
                  />
                  {{ item.name || "" }}
                </span>
                <span v-else>
                  <IconConnection
                    :state="item.connector_status"
                    :disabled="!item.enabled"
                  />
                  {{ item.name || "" }}
                </span>
                <b
                  class="icon-gap btn-xs edit-icon"
                  v-if="canEditConnector"
                  @click.stop.prevent="editConnector(item)"
                  :title="
                    `${$t('hints.access_to_configuration_form')} ${$tc(
                      'connector'
                    ).toLowerCase()}`
                  "
                  ><i class="fa fa-wrench clicable-icon"></i
                ></b>
              </span>
            </td>
            <!-- serial number -->
            <td
              v-if="showField('serial_number')"
              data-testid="item.sn"
              :title="itemTitle(item)"
            >
              {{ item.serial_number }}
            </td>
            <!-- location -->
            <td v-if="showField('location')" :title="itemTitle(item)">
              <span class="text-default">{{ item.location_name || "" }}</span>
            </td>
            <!-- device -->
            <td
              v-if="showField('device')"
              class="device-column"
              :class="['not-clicable', {'text-muted': !item.device_enabled}]"
              @click.stop.prevent="onDeviceSelected(item)"
              :title="`#${item.id}-${item.device_name || item.name || ''}`"
            >
              <span>
                <IconConnection
                  :state="deviceConnectionStatus(item)"
                  :disabled="!item.device_enabled"
                />
                <b
                  v-if="$can('view', 'HistoricoAlarmeAcesso')"
                  :class="['icon-gap', {disabled: !item.device_enabled}]"
                >
                  <router-link
                    v-bind:to="'/dashboard/alarms/' + item.id"
                    :title="$t('titles.access_alarms_history')"
                  >
                    <i
                      class="fa fa-bell text-danger clicable-icon"
                      v-if="item.device_has_active_alarms"
                    ></i>
                    <i class="fa fa-bell clicable-icon" v-else></i>
                  </router-link>
                </b>
                {{ item.device_name || item.name || "" }}
                <i class="fa fa-dashboard"></i>
                <b
                  class="icon-gap edit-icon"
                  v-if="canEditDevice"
                  @click.stop.prevent="editDevice(item)"
                  :title="
                    `${$t('hints.access_to_configuration_form')} ${$tc(
                      'device'
                    ).toLowerCase()}`
                  "
                  ><i class="fa fa-wrench clicable-icon"></i>
                </b>
              </span>
            </td>
            <!-- process area -->
            <td
              v-if="showField('process_area')"
              :class="{'text-muted': !item.device_enabled}"
              :title="`click: ${$t('titles.access_dashboard')}`"
            >
              <span>{{ item.device_process_area_name }}</span>
            </td>
            <!-- CUSTOM FIELDS -->
            <td
              v-for="(customField, index2) in equipmentCustomFields"
              v-bind:key="index2"
            >
              {{ getCustomFieldValue(item, customField) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10">{{ $t("no_equipment_was_found") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {isMQTT} from "@/services/connector.js";
import TablePaginationNav from "@/components/table-pagination-nav.vue";
import tho from "@/components/tho.vue";
import IconConnection from "@/components/icons/icon-connection.vue";
export default {
  name: "EquipmentTablePanel",
  components: {
    TablePaginationNav,
    tho,
    IconConnection
  },
  props: {
    searchEnabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    sortEnabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    equipmentList: {
      type: Array,
      required: false,
      default: () => []
    },
    ids: {
      type: Array,
      required: false,
      default: () => []
    },
    pagination: {
      type: Boolean,
      required: false,
      default: () => false
    },
    clientSort: {
      type: Boolean,
      default: () => true,
      required: false
    },
    inlineFilter: {
      type: Boolean,
      default: () => false,
      required: false
    },
    multiSelection: {
      type: Object,
      required: false,
      default: () => ({key: null, values: []})
    },
    allowNull: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      currentSort: {
        column: "dftIndex",
        asc: true
      },
      pageItems: [],
      serverSort: false,
      device_id: "",
      contract_alert_icon: false,
      fixedEquipmentProperties: [
        "alarm_status",
        "connection_status",
        "connector",
        "serial_number",
        "location",
        "device",
        "process_area"
      ]
    };
  },
  computed: {
    showPagination() {
      return this.pagination && this.pageSize && this.nItems > this.pageSize;
    },
    pageSize() {
      return parseInt(
        (this.$root.config &&
          this.$root.config.equipment_selection &&
          this.$root.config.equipment_selection.page_size) ||
          0 // no pagination
      );
    },
    nItems() {
      return (this.orderedEquipmentList || []).length;
    },
    items() {
      return this.pageSize &&
        this.pageItems.length &&
        this.nItems > this.pageSize
        ? this.pageItems
        : this.orderedEquipmentList;
    },
    orderedEquipmentList() {
      let self = this;
      let asc = self.currentSort.asc;
      let attr = self.currentSort.column;
      let ret = [];
      let idList = (this.ids || []).map((i) => parseInt(i));
      if (self.equipmentList) {
        // let lst = JSON.parse(JSON.stringify(self.equipmentList));
        let lst = structuredClone(self.equipmentList);
        ret = lst
          .filter(({id}) => {
            return idList.length ? idList.indexOf(parseInt(id)) >= 0 : true;
          })
          .map((i) => {
            // i.sn = self.serial_number(i);
            for (var field_name in i.device || {}) {
              if (typeof i.device[field_name] == "object") {
                for (var field_name_deep in i.device[field_name] || {}) {
                  i["device_" + field_name + "_" + field_name_deep] =
                    i.device[field_name][field_name_deep];
                }
              } else {
                i["device_" + field_name] = i.device[field_name];
              }
            }
            i["dftIndex"] = `${i.has_active_alarms ? 0 : 1}${
              i.device_has_active_alarms ? 0 : 1
            }${i.name.toUpperCase()}${i.device.name.toUpperCase()}`;
            return i;
          });
        if (!this.serverSort) {
          ret = ret.sort((a, b) => {
            if (attr in a && attr in b) {
              if (asc ? a[attr] > b[attr] : b[attr] > a[attr]) return 1;
              if (asc ? b[attr] > a[attr] : a[attr] > b[attr]) return -1;
            }
            return 0;
          });
        }
      }
      return ret;
    },
    equipmentCustomFields() {
      let self = this;
      return (this.$root.config.equipment_selection.cols || []).filter(function(
        i
      ) {
        return (
          (!("enabled" in i) || i.enabled) &&
          self.fixedEquipmentProperties.indexOf(i.name) == -1
        );
      });
    },
    canEditConnector() {
      return this.$can("manage", "EquipamentoCadastro");
    },
    canEditDevice() {
      return this.$can("manage", "EstacaoCadastro");
    },
    nSelected() {
      return (this.selected || []).length;
    },
    fields() {
      return [
        {
          name: "alarm_status",
          title: "alarm",
          visible: this.showField("alarm_status")
        },
        {
          name: "connection_status",
          title: "connection",
          visible: this.showField("connection_status")
        },
        {
          name: "name",
          title: "connector",
          visible: this.showField("connector")
        },
        {
          name: "serial_number",
          title: "s_n",
          visible: this.showField("serial_number")
        },
        {
          name: "location_name",
          title: "location",
          visible: this.showField("location")
        },
        {
          name: "device_name",
          title: "device",
          visible: this.showField("device")
        },
        {
          name: "device_process_area_name",
          title: "process_area",
          visible: this.showField("process_area")
        }
      ]
        .concat(this.equipmentCustomFields)
        .filter(({visible}) => visible ?? visible);
    },
    columns() {
      return (this.fields || []).map((i) => {
        return {
          ...i,
          asc:
            !this.currentSort ||
            !this.currentSort.column ||
            this.currentSort.column != i.name
              ? undefined
              : this.currentSort.asc
        };
      });
    }
  },
  methods: {
    isMQTTConnector(item) {
      return isMQTT(item);
    },
    editConnector(connector) {
      let connector_id = (connector && connector.id) || 0;
      if (connector_id) {
        this.$router.push(`/dashboard/edit/connector/${connector_id}`);
      }
    },
    editDevice(item) {
      let device = (item && item.device) || null;
      let device_id = (device && device.id) || 0;
      let connector_id = (item && item.id) || 0;
      if (connector_id && device) {
        this.$router.push(
          `/dashboard/edit/connector/${connector_id}/device/${device_id}`
        );
      }
    },
    onPageChanged(lst) {
      this.$set(this, "pageItems", lst);
    },
    onLoadNextPage() {
      this.$emit("loadNextPage");
    },
    onSelect(item) {
      if (
        this.allowNull &&
        this.device_id &&
        this.device_id == item.device_id
      ) {
        this.device_id = "";
        this.$emit("select");
      } else {
        if (!this.allowNull) {
          this.openDeviceScreen(item);
          return;
        }
        this.device_id = item.device_id;
        let query = {
          connector_id: item.id,
          device_id: item.device_id
        };
        this.$emit("select", query);
      }
    },
    getCustomFieldValue(equipment, customField) {
      let value =
        equipment &&
        customField &&
        customField.name &&
        customField.name in equipment
          ? equipment[customField.name]
          : ("user_data" in equipment &&
              equipment.user_data &&
              "extended_properties" in equipment.user_data &&
              equipment.user_data.extended_properties &&
              customField.name in equipment.user_data.extended_properties &&
              equipment.user_data.extended_properties[customField.name]) ||
            "";
      // // exceptions
      // if (
      //   customField &&
      //   customField.name &&
      //   customField.name == "numero_serie_maquina"
      // ) {
      //   value = value || this.serial_number(equipment);
      // }
      return value;
    },
    // serial_number: function(equipment) {
    //   return equipment.numero_serie_maquina || equipment.numero_serie || "";
    // },
    onSort(attr) {
      this.currentSort.asc =
        attr == this.currentSort.column ? !this.currentSort.asc : true;
      this.currentSort.column = attr;
      if (!this.clientSort && this.showPagination) {
        // not all displayed columns can be sorted
        let api_field_map = {
          name: "connector_name",
          is_connected: "connector_status_name",
          device_name: "name",
          device_is_connected: "device_status_name",
          device_has_active_alarms: "number_active_alarms",
          device_process_area_name: "process_area_name"
        };
        if (this.currentSort.column in api_field_map) {
          let field =
            (this.currentSort.asc ? "" : "-") +
            api_field_map[this.currentSort.column];
          this.$emit("multiColumnSort", [field]);
          this.serverSort = true;
          return;
        }
      }
      this.serverSort = false;
    },
    showField(name) {
      let found = (this.$root.config.equipment_selection.cols || []).find(
        (i) => name == i.name && (!("enabled" in i) || i.enabled)
      );
      return found != undefined;
    },
    onDeviceSelected(item) {
      this.openDeviceScreen(item);
    },
    openDeviceScreen(item) {
      let device = item?.device || null;
      let path = `/dashboard/equipment/${item.id}`;
      if (device && device.screen_id) {
        path = `/dashboard/equipment/${item.id}/${device?.screen_id}`;
        if (this.$route.path == path) {
          if (this.$route.query.device_id == device.id) {
            return;
          }
          // since it is the same path, does not add on history.
          // it will make easier to return to the caller page instead
          let query = {...this.$route.query};
          query.device_id = device.id;
          this.$router.replace({query: query}).then(() => {
            console.log("changed");
          });
        } else {
          this.$router.push(`${path}?device_id=${device.id}`);
        }
        this.device_id = device.id;
      } else {
        if (this.$route.path == path) {
          return;
        }
        this.device_id = "";
        this.$router.push(path);
        return;
      }
    },
    isSelected(id) {
      return this.multiSelection.values.some(
        (i) => parseInt(id) === parseInt(i)
      );
    },
    syncColumnResizeHandle() {
      this.$nextTick(() => {
        if (this.$refs.table) {
          var _rc = $(this.$refs.table).data("resizableColumns");
          if (_rc) {
            _rc.syncHandleWidths();
          } else {
            setTimeout(
              () => {
                if (this.$refs.table) {
                  $(this.$refs.table).resizableColumns({
                    resizeFromBody: false
                  });
                }
              },
              500,
              this
            );
          }
        }
      });
    },
    itemTitle(item) {
      return item
        ? `#${item.id} ${item.name}\
        ${
          item.description
            ? "\n" + this.$t("description") + ": " + item.description
            : ""
        }\
        \n${this.$t("last_activity_at")}: ${
            item.last_activity_at
              ? moment(item.last_activity_at).format("DD/MM/YYYY HH:mm:ss")
              : this.$t("not_defined")
          }`
        : "";
    },
    deviceConnectionStatus(item) {
      if (item.connector_status && item.connector_status.number != 1)
        return item.connector_status;
      return (
        (item.device && item.device.device_status) ||
        item.connector_status ||
        null
      );
    }
  },
  mounted() {
    // this.serverSort = !this.clientSort;
    this.device_id =
      this.$store.getters["deviceId"] ||
      this.$utils.gup("deviceId") ||
      this.$utils.gup("device_id") ||
      "";
    this.$nextTick(() => {
      this.syncColumnResizeHandle();
      // setTimeout(() => {
      // }, 1000);
    });
  }
};
</script>

<style scoped>
.clicable-row {
  opacity: 0.8;
}

.skin-dark .clicable-row {
  opacity: 1;
}

.clicable-row:hover {
  cursor: pointer;
  opacity: 1;
}

.skin-dark tr.clicable-row:hover > td {
  color: #fff;
}

.clicable-icon:hover {
  color: #f39c12;
  cursor: pointer;
  opacity: 1;
}

.skin-dark .clicable-icon:hover {
  color: #fff;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  /* white-space: nowrap; */
  max-width: 400px;
}

.table > tbody > tr.active > td {
  background-color: #337ab7;
  color: white;
}

button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}

a.alarm-link {
  min-width: 100px;
}

a.alarm-link .fa-link {
  color: transparent;
}

a.alarm-link:hover .fa-link {
  color: inherit;
  text-decoration: none;
}
.connected {
  color: inherit; /* TODO: validate it with team */
}
.disconnected {
  color: inherit; /* TODO: validate it with team */
}
.icon-gap {
  margin: 0 5px;
}
.icon-gap.disabled {
  opacity: 0.6;
}
.not-clicable {
  overflow: hidden;
}
.not-clicable:hover {
  cursor: default;
}

.clicable:hover {
  cursor: pointer;
}

.clicable-box {
  width: 100%;
  min-height: 16px;
  display: inline-block;
}

.device-column:hover {
  cursor: pointer;
  background-color: rgb(225, 243, 250);
}

.skin-dark .device-column:hover {
  background-color: transparent;
}

.device-column > span > i.fa-dashboard {
  color: transparent;
  margin-left: 5px;
}

.device-column:hover > span > i.fa-dashboard {
  color: #337ab7;
  opacity: 0.7;
}

td {
  position: relative;
}
td > span > .edit-icon {
  position: absolute;
  top: 0.5em;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
}

.skin-dark td > span > .edit-icon {
  background-color: transparent;
}

.td-check {
  width: 24px;
  max-width: 24px;
}

@media (max-width: 768px) {
  .table-container {
    overflow: auto;
  }
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    white-space: nowrap;
  }
}
</style>
